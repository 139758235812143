<template>
    <b-dropdown id="langddm"
                variant="link"
                dropleft
                toggle-class="text-decoration-none"
                size="sm">
        <template v-slot:button-content>
            <div class="d-inline-block nav-link p-0">{{ language && language.text }}</div>
        </template>
        <b-dropdown-item v-for="option in languageOptions" :key="option.value"
                         @click="onChangeLanguage(option)" v-if="option.value !== language.value">
            <div class="d-inline-block nav-link p-0">{{ option.text }}</div>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import translate from "../translation/translate";

export default {
    name: "LanguageSelector",
    data() {
        return {
            languageOptions: [
                {value: 'ru', text: 'RU'},
                {value: 'uz', text: "O'Z"},
                {value: 'oz', text: 'ЎЗ'},
            ],
        }
    },
    computed: {
        language: {
            get() {
                let localName = translate.getUserLocaleName();
                return this.languageOptions.find((option) => option.value === localName);
            },
            set(lang) {
                translate.changeLocale(lang.value);
                location.reload();
            }
        },
    },
    methods: {
        onChangeLanguage(option) {
            this.language = option;
            localStorage.removeItem('selectedTypeQuantity');
        },
    },
}
</script>

<style scoped>

</style>
