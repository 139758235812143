export default [{
    "item": {
        "id": "64919d27854f916c76ec3989",
        "created_at": "2023-06-20 17:35:51",
        "updated_at": "2023-06-20 17:35:51",
        "deleted_at": null,
        "is_deleted": false,
        "name": "Блокнот А6 (Office time black)",
        "measurement": 0,
        "package_measurements": [],
        "code": "",
        "sku": "CSPR-00011",
        "barcodes": [],
        "is_state_controlled": false,
        "is_favorite": false,
        "category": {
            "id": "649176c8854f916c76ec38b7",
            "name": "Блокноты"
        },
        "tax": null,
        "images": [
            {
                "id": "64919d27854f916c76ec398a",
                "name": "product.jpeg",
                "path": "62d00bf2e86565691e261807/64919d27854f916c76ec3989/64919d27854f916c76ec398a/product.jpeg"
            }
        ],
        "description": "",
        "organization_id": "62d00bf2e86565691e261807",
        "tasnif_info": null,
        "description_attributes": [
            {
                "key": "brand",
                "value": "Принт.уз"
            }
        ]
    },
    "price": {
        "id": 139630,
        "created_at": "2023-06-20 05:35:51",
        "updated_at": "2023-06-20 05:35:51",
        "is_active": true,
        "item": {
            "id": 80530,
            "global_item_id": "64919d27854f916c76ec3989",
            "global_organization_id": "62d00bf2e86565691e261807"
        },
        "common_price": {
            "id": 279151,
            "created_at": "2023-06-20 05:35:51",
            "updated_at": "2023-06-20 05:35:51",
            "amount": 3714,
            "currency": {
                "id": 47,
                "global_currency_id": 41,
                "name": "UZS",
                "global_organization_id": "62d00bf2e86565691e261807",
                "is_national": true
            }
        },
        "bulk_price": {
            "id": 279150,
            "created_at": "2023-06-20 05:35:51",
            "updated_at": "2023-06-20 05:35:51",
            "amount": 3714,
            "currency": {
                "id": 47,
                "global_currency_id": 41,
                "name": "UZS",
                "global_organization_id": "62d00bf2e86565691e261807",
                "is_national": true
            }
        },
        "contractor": null,
        "global_account_id": null
    },
    "warehouse_states": {
        "id": "64919d27854f916c76ec3989",
        "name": "Блокнот А6 (Office time black)",
        "organization_id": "62d00bf2e86565691e261807",
        "warehouse_items": []
    }
},
    {
        "item": {
            "id": "64919d2a854f916c76ec398b",
            "created_at": "2023-06-20 17:35:54",
            "updated_at": "2023-06-20 17:35:54",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Блокнот А6 (Note green)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "CSPR-00013",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b7",
                "name": "Блокноты"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d2a854f916c76ec398c",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d2a854f916c76ec398b/64919d2a854f916c76ec398c/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Принт.уз"
                }
            ]
        },
        "price": {
            "id": 139632,
            "created_at": "2023-06-20 05:35:55",
            "updated_at": "2023-06-20 05:35:55",
            "is_active": true,
            "item": {
                "id": 80531,
                "global_item_id": "64919d2a854f916c76ec398b",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279155,
                "created_at": "2023-06-20 05:35:55",
                "updated_at": "2023-06-20 05:35:55",
                "amount": 3268,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279154,
                "created_at": "2023-06-20 05:35:55",
                "updated_at": "2023-06-20 05:35:55",
                "amount": 3268,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d2a854f916c76ec398b",
            "name": "Блокнот А6 (Note green)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d2b854f916c76ec398d",
            "created_at": "2023-06-20 17:35:55",
            "updated_at": "2023-06-20 17:35:55",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Блокнот А6 (Huskies)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "CSPR-00017",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b7",
                "name": "Блокноты"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d2b854f916c76ec398e",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d2b854f916c76ec398d/64919d2b854f916c76ec398e/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Принт.уз"
                }
            ]
        },
        "price": {
            "id": 139634,
            "created_at": "2023-06-20 05:35:55",
            "updated_at": "2023-06-20 05:35:55",
            "is_active": true,
            "item": {
                "id": 80532,
                "global_item_id": "64919d2b854f916c76ec398d",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279159,
                "created_at": "2023-06-20 05:35:55",
                "updated_at": "2023-06-20 05:35:55",
                "amount": 3714,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279158,
                "created_at": "2023-06-20 05:35:55",
                "updated_at": "2023-06-20 05:35:55",
                "amount": 3714,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d2b854f916c76ec398d",
            "name": "Блокнот А6 (Huskies)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d2c854f916c76ec398f",
            "created_at": "2023-06-20 17:35:56",
            "updated_at": "2023-06-20 17:35:56",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Блокнот А6 (Art)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "CSPR-00023",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b7",
                "name": "Блокноты"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d2c854f916c76ec3990",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d2c854f916c76ec398f/64919d2c854f916c76ec3990/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Принт.уз"
                }
            ]
        },
        "price": {
            "id": 139636,
            "created_at": "2023-06-20 05:35:56",
            "updated_at": "2023-06-20 05:35:56",
            "is_active": true,
            "item": {
                "id": 80533,
                "global_item_id": "64919d2c854f916c76ec398f",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279163,
                "created_at": "2023-06-20 05:35:56",
                "updated_at": "2023-06-20 05:35:56",
                "amount": 3173,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279162,
                "created_at": "2023-06-20 05:35:56",
                "updated_at": "2023-06-20 05:35:56",
                "amount": 3173,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d2c854f916c76ec398f",
            "name": "Блокнот А6 (Art)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d2d854f916c76ec3991",
            "created_at": "2023-06-20 17:35:57",
            "updated_at": "2023-06-20 17:35:57",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Блокнот А6 (Flowery F)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "CSPR-00025",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b7",
                "name": "Блокноты"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d2d854f916c76ec3992",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d2d854f916c76ec3991/64919d2d854f916c76ec3992/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Принт.уз"
                }
            ]
        },
        "price": {
            "id": 139638,
            "created_at": "2023-06-20 05:35:57",
            "updated_at": "2023-06-20 05:35:57",
            "is_active": true,
            "item": {
                "id": 80534,
                "global_item_id": "64919d2d854f916c76ec3991",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279167,
                "created_at": "2023-06-20 05:35:57",
                "updated_at": "2023-06-20 05:35:57",
                "amount": 4432,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279166,
                "created_at": "2023-06-20 05:35:57",
                "updated_at": "2023-06-20 05:35:57",
                "amount": 4432,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d2d854f916c76ec3991",
            "name": "Блокнот А6 (Flowery F)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d2e854f916c76ec3993",
            "created_at": "2023-06-20 17:35:58",
            "updated_at": "2023-06-20 17:35:58",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Блокнот А6 (Line)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "CSPR-00015",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b7",
                "name": "Блокноты"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d2e854f916c76ec3994",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d2e854f916c76ec3993/64919d2e854f916c76ec3994/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Принт.уз"
                }
            ]
        },
        "price": {
            "id": 139640,
            "created_at": "2023-06-20 05:35:58",
            "updated_at": "2023-06-20 05:35:58",
            "is_active": true,
            "item": {
                "id": 80535,
                "global_item_id": "64919d2e854f916c76ec3993",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279171,
                "created_at": "2023-06-20 05:35:58",
                "updated_at": "2023-06-20 05:35:58",
                "amount": 3714,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279170,
                "created_at": "2023-06-20 05:35:58",
                "updated_at": "2023-06-20 05:35:58",
                "amount": 3714,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d2e854f916c76ec3993",
            "name": "Блокнот А6 (Line)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d2e854f916c76ec3995",
            "created_at": "2023-06-20 17:35:58",
            "updated_at": "2023-06-20 17:35:58",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Блокнот А6 (Pans)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "CSPR-00019",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b7",
                "name": "Блокноты"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d2e854f916c76ec3996",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d2e854f916c76ec3995/64919d2e854f916c76ec3996/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Принт.уз"
                }
            ]
        },
        "price": {
            "id": 139642,
            "created_at": "2023-06-20 05:35:59",
            "updated_at": "2023-06-20 05:35:59",
            "is_active": true,
            "item": {
                "id": 80536,
                "global_item_id": "64919d2e854f916c76ec3995",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279175,
                "created_at": "2023-06-20 05:35:59",
                "updated_at": "2023-06-20 05:35:59",
                "amount": 3173,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279174,
                "created_at": "2023-06-20 05:35:59",
                "updated_at": "2023-06-20 05:35:59",
                "amount": 3173,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d2e854f916c76ec3995",
            "name": "Блокнот А6 (Pans)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d30854f916c76ec3997",
            "created_at": "2023-06-20 17:36:00",
            "updated_at": "2023-06-20 17:36:00",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Блокнот А6 (Stars)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "CSPR-00021",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b7",
                "name": "Блокноты"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d30854f916c76ec3998",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d30854f916c76ec3997/64919d30854f916c76ec3998/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Принт.уз"
                }
            ]
        },
        "price": {
            "id": 139644,
            "created_at": "2023-06-20 05:36:01",
            "updated_at": "2023-06-20 05:36:01",
            "is_active": true,
            "item": {
                "id": 80537,
                "global_item_id": "64919d30854f916c76ec3997",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279179,
                "created_at": "2023-06-20 05:36:01",
                "updated_at": "2023-06-20 05:36:01",
                "amount": 3714,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279178,
                "created_at": "2023-06-20 05:36:01",
                "updated_at": "2023-06-20 05:36:01",
                "amount": 3714,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d30854f916c76ec3997",
            "name": "Блокнот А6 (Stars)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d31854f916c76ec3999",
            "created_at": "2023-06-20 17:36:01",
            "updated_at": "2023-06-20 17:36:01",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бум.оф.Double A, 80гр., А4. 100л. Orange Saffron",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "10015",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d31854f916c76ec399a",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d31854f916c76ec3999/64919d31854f916c76ec399a/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139646,
            "created_at": "2023-06-20 05:36:01",
            "updated_at": "2023-06-20 05:36:01",
            "is_active": true,
            "item": {
                "id": 80538,
                "global_item_id": "64919d31854f916c76ec3999",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279183,
                "created_at": "2023-06-20 05:36:01",
                "updated_at": "2023-06-20 05:36:01",
                "amount": 13689,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279182,
                "created_at": "2023-06-20 05:36:01",
                "updated_at": "2023-06-20 05:36:01",
                "amount": 13689,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d31854f916c76ec3999",
            "name": "Бум.оф.Double A, 80гр., А4. 100л. Orange Saffron",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d33854f916c76ec399b",
            "created_at": "2023-06-20 17:36:03",
            "updated_at": "2023-06-20 17:36:03",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бумага офисная Double A, 80гр., А4. 100л. Yellow Canary",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "10002",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d33854f916c76ec399c",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d33854f916c76ec399b/64919d33854f916c76ec399c/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139648,
            "created_at": "2023-06-20 05:36:03",
            "updated_at": "2023-06-20 05:36:03",
            "is_active": true,
            "item": {
                "id": 80539,
                "global_item_id": "64919d33854f916c76ec399b",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279187,
                "created_at": "2023-06-20 05:36:03",
                "updated_at": "2023-06-20 05:36:03",
                "amount": 13577,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279186,
                "created_at": "2023-06-20 05:36:03",
                "updated_at": "2023-06-20 05:36:03",
                "amount": 13577,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d33854f916c76ec399b",
            "name": "Бумага офисная Double A, 80гр., А4. 100л. Yellow Canary",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d34854f916c76ec399d",
            "created_at": "2023-06-20 17:36:04",
            "updated_at": "2023-06-20 17:36:04",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бумага офисная Double A, 80гр., А4. 500л. Yellow Canary",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "50008",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d34854f916c76ec399e",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d34854f916c76ec399d/64919d34854f916c76ec399e/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139650,
            "created_at": "2023-06-20 05:36:04",
            "updated_at": "2023-06-20 05:36:04",
            "is_active": true,
            "item": {
                "id": 80540,
                "global_item_id": "64919d34854f916c76ec399d",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279191,
                "created_at": "2023-06-20 05:36:04",
                "updated_at": "2023-06-20 05:36:04",
                "amount": 62807,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279190,
                "created_at": "2023-06-20 05:36:04",
                "updated_at": "2023-06-20 05:36:04",
                "amount": 62807,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d34854f916c76ec399d",
            "name": "Бумага офисная Double A, 80гр., А4. 500л. Yellow Canary",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d34854f916c76ec399f",
            "created_at": "2023-06-20 17:36:04",
            "updated_at": "2023-06-20 17:36:04",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бумага офисная Double A, 80гр., А4. 500л Orange Saffron",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "50027",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d34854f916c76ec39a0",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d34854f916c76ec399f/64919d34854f916c76ec39a0/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139652,
            "created_at": "2023-06-20 05:36:04",
            "updated_at": "2023-06-20 05:36:04",
            "is_active": true,
            "item": {
                "id": 80541,
                "global_item_id": "64919d34854f916c76ec399f",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279195,
                "created_at": "2023-06-20 05:36:04",
                "updated_at": "2023-06-20 05:36:04",
                "amount": 65455,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279194,
                "created_at": "2023-06-20 05:36:04",
                "updated_at": "2023-06-20 05:36:04",
                "amount": 65455,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d34854f916c76ec399f",
            "name": "Бумага офисная Double A, 80гр., А4. 500л Orange Saffron",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d34854f916c76ec39a1",
            "created_at": "2023-06-20 17:36:04",
            "updated_at": "2023-06-20 17:36:04",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бумага офисная Double A, 80гр., А4. 100л. Orange Gold",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "10016",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d34854f916c76ec39a2",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d34854f916c76ec39a1/64919d34854f916c76ec39a2/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139654,
            "created_at": "2023-06-20 05:36:04",
            "updated_at": "2023-06-20 05:36:04",
            "is_active": true,
            "item": {
                "id": 80542,
                "global_item_id": "64919d34854f916c76ec39a1",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279199,
                "created_at": "2023-06-20 05:36:04",
                "updated_at": "2023-06-20 05:36:04",
                "amount": 13577,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279198,
                "created_at": "2023-06-20 05:36:04",
                "updated_at": "2023-06-20 05:36:04",
                "amount": 13577,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d34854f916c76ec39a1",
            "name": "Бумага офисная Double A, 80гр., А4. 100л. Orange Gold",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d35854f916c76ec39a3",
            "created_at": "2023-06-20 17:36:05",
            "updated_at": "2023-06-20 17:36:05",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бумага офисная Double A, 80гр., А4. 25л. Orange Gold",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "25006",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d35854f916c76ec39a4",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d35854f916c76ec39a3/64919d35854f916c76ec39a4/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139656,
            "created_at": "2023-06-20 05:36:05",
            "updated_at": "2023-06-20 05:36:05",
            "is_active": true,
            "item": {
                "id": 80543,
                "global_item_id": "64919d35854f916c76ec39a3",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279203,
                "created_at": "2023-06-20 05:36:05",
                "updated_at": "2023-06-20 05:36:05",
                "amount": 5353,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279202,
                "created_at": "2023-06-20 05:36:05",
                "updated_at": "2023-06-20 05:36:05",
                "amount": 5353,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d35854f916c76ec39a3",
            "name": "Бумага офисная Double A, 80гр., А4. 25л. Orange Gold",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d35854f916c76ec39a5",
            "created_at": "2023-06-20 17:36:05",
            "updated_at": "2023-06-20 17:36:05",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бумага офисная Double A, 80гр., А4. 25л. Orange Saffron",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "25004",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d35854f916c76ec39a6",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d35854f916c76ec39a5/64919d35854f916c76ec39a6/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139658,
            "created_at": "2023-06-20 05:36:06",
            "updated_at": "2023-06-20 05:36:06",
            "is_active": true,
            "item": {
                "id": 80544,
                "global_item_id": "64919d35854f916c76ec39a5",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279207,
                "created_at": "2023-06-20 05:36:06",
                "updated_at": "2023-06-20 05:36:06",
                "amount": 5353,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279206,
                "created_at": "2023-06-20 05:36:06",
                "updated_at": "2023-06-20 05:36:06",
                "amount": 5353,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d35854f916c76ec39a5",
            "name": "Бумага офисная Double A, 80гр., А4. 25л. Orange Saffron",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d36854f916c76ec39a7",
            "created_at": "2023-06-20 17:36:06",
            "updated_at": "2023-06-20 17:36:06",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Бумага офисная Double A, 80гр., А4. 500л Orange Gold",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "50029",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b5",
                "name": "Бумага А4"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d36854f916c76ec39a8",
                    "name": "product.bmp",
                    "path": "62d00bf2e86565691e261807/64919d36854f916c76ec39a7/64919d36854f916c76ec39a8/product.bmp"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Double A"
                }
            ]
        },
        "price": {
            "id": 139660,
            "created_at": "2023-06-20 05:36:06",
            "updated_at": "2023-06-20 05:36:06",
            "is_active": true,
            "item": {
                "id": 80545,
                "global_item_id": "64919d36854f916c76ec39a7",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279211,
                "created_at": "2023-06-20 05:36:06",
                "updated_at": "2023-06-20 05:36:06",
                "amount": 94950,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279210,
                "created_at": "2023-06-20 05:36:06",
                "updated_at": "2023-06-20 05:36:06",
                "amount": 94950,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d36854f916c76ec39a7",
            "name": "Бумага офисная Double A, 80гр., А4. 500л Orange Gold",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d36854f916c76ec39a9",
            "created_at": "2023-06-20 17:36:06",
            "updated_at": "2023-06-20 17:36:06",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Набор Пластилиновая аппликация.В поле",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "26С 1611-08",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176cf854f916c76ec3914",
                "name": "Наборы для творчества"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d36854f916c76ec39aa",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d36854f916c76ec39a9/64919d36854f916c76ec39aa/product.jpeg"
                }
            ],
            "description": "Набор предназначен для создания объёмной картины на красочной карточке с сюжетом в технике аппликация.  В качестве разноцветных кусочков послужат фигурки, предварительно вырезанные при помощи формочек - выемок из мягкого воскового пластилина. Поэтому, при заполнении формочки пластилином ребенок получает готовую фигурку, а разноцветные кусочки пластилина, которые входят в состав набора, добавят яркости и сделают работу не похожей на другие.\n\n Пластилин мягкий восковой 6 цв\n 3 цветных рисунка Фо",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Luch"
                }
            ]
        },
        "price": {
            "id": 139662,
            "created_at": "2023-06-20 05:36:06",
            "updated_at": "2023-06-20 05:36:06",
            "is_active": true,
            "item": {
                "id": 80546,
                "global_item_id": "64919d36854f916c76ec39a9",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279215,
                "created_at": "2023-06-20 05:36:06",
                "updated_at": "2023-06-20 05:36:06",
                "amount": 22954,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279214,
                "created_at": "2023-06-20 05:36:06",
                "updated_at": "2023-06-20 05:36:06",
                "amount": 22954,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d36854f916c76ec39a9",
            "name": "Набор Пластилиновая аппликация.В поле",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d36854f916c76ec39ab",
            "created_at": "2023-06-20 17:36:06",
            "updated_at": "2023-06-20 17:36:06",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Набор Пластилиновая аппликация.На пруду",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "26С 1612-08",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176cf854f916c76ec3914",
                "name": "Наборы для творчества"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d36854f916c76ec39ac",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d36854f916c76ec39ab/64919d36854f916c76ec39ac/product.jpeg"
                }
            ],
            "description": "Набор предназначен для создания объёмной картины на красочной карточке с сюжетом в технике аппликация.  В качестве разноцветных кусочков послужат фигурки, предварительно вырезанные при помощи формочек - выемок из мягкого воскового пластилина. Поэтому, при заполнении формочки пластилином ребенок получает готовую фигурку, а разноцветные кусочки пластилина, которые входят в состав набора, добавят яркости и сделают работу не похожей на другие.\n\n Пластилин мягкий восковой 6 цв\n 3 цветных рисунка",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Luch"
                }
            ]
        },
        "price": {
            "id": 139664,
            "created_at": "2023-06-20 05:36:07",
            "updated_at": "2023-06-20 05:36:07",
            "is_active": true,
            "item": {
                "id": 80547,
                "global_item_id": "64919d36854f916c76ec39ab",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279219,
                "created_at": "2023-06-20 05:36:07",
                "updated_at": "2023-06-20 05:36:07",
                "amount": 22954,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279218,
                "created_at": "2023-06-20 05:36:07",
                "updated_at": "2023-06-20 05:36:07",
                "amount": 22954,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d36854f916c76ec39ab",
            "name": "Набор Пластилиновая аппликация.На пруду",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d37854f916c76ec39ad",
            "created_at": "2023-06-20 17:36:07",
            "updated_at": "2023-06-20 17:36:07",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Восковые мелки Классика( круглые) 12 цв",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "12С 861-08",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38ac",
                "name": "Карандаши цветные"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d37854f916c76ec39ae",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d37854f916c76ec39ad/64919d37854f916c76ec39ae/product.jpeg"
                }
            ],
            "description": "Мелки изготавливаются на основе восков с добавлением масла и высококачественных пигментов. Предназначены для рисования и письма по разным поверхностям бумаге, картону, дереву, ткани, стеклу, камням.\n\n  Яркие насыщенные цвета\n  Прочные\n  Не требуют затачивания, но при необходимости можно использовать обычную точилку\n  Экономичные\n  Штрихи свободно наносятся на поверхность\n  Не пачкают руки\n  Прекрасно рисуют в различных техниках в сочетании с акварелью, гуашью и т.д\n  Безопасны для детей (безоп",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Luch"
                }
            ]
        },
        "price": {
            "id": 139666,
            "created_at": "2023-06-20 05:36:07",
            "updated_at": "2023-06-20 05:36:07",
            "is_active": true,
            "item": {
                "id": 80548,
                "global_item_id": "64919d37854f916c76ec39ad",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279223,
                "created_at": "2023-06-20 05:36:07",
                "updated_at": "2023-06-20 05:36:07",
                "amount": 9936,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279222,
                "created_at": "2023-06-20 05:36:07",
                "updated_at": "2023-06-20 05:36:07",
                "amount": 9936,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d37854f916c76ec39ad",
            "name": "Восковые мелки Классика( круглые) 12 цв",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d37854f916c76ec39af",
            "created_at": "2023-06-20 17:36:07",
            "updated_at": "2023-06-20 17:36:07",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Восковые мелки Классика( круглые) 24 цв",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "12С 862-08",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38ac",
                "name": "Карандаши цветные"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d37854f916c76ec39b0",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d37854f916c76ec39af/64919d37854f916c76ec39b0/product.jpeg"
                }
            ],
            "description": "Мелки изготавливаются на основе восков с добавлением масла и высококачественных пигментов. Предназначены для рисования и письма по разным поверхностям бумаге, картону, дереву, ткани, стеклу, камням.\n\n  Яркие насыщенные цвета\n  Прочные\n  Не требуют затачивания, но при необходимости можно использовать обычную точилку\n  Экономичные\n  Штрихи свободно наносятся на поверхность\n yes Не пачкают руки\n Прекрасно рисуют в различных техниках в сочетании с акварелью, гуашью и т.д\n  Безопасны для детей (без",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Luch"
                }
            ]
        },
        "price": {
            "id": 139668,
            "created_at": "2023-06-20 05:36:07",
            "updated_at": "2023-06-20 05:36:07",
            "is_active": true,
            "item": {
                "id": 80549,
                "global_item_id": "64919d37854f916c76ec39af",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279227,
                "created_at": "2023-06-20 05:36:07",
                "updated_at": "2023-06-20 05:36:07",
                "amount": 19596,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279226,
                "created_at": "2023-06-20 05:36:07",
                "updated_at": "2023-06-20 05:36:07",
                "amount": 19596,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d37854f916c76ec39af",
            "name": "Восковые мелки Классика( круглые) 24 цв",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d38854f916c76ec39b1",
            "created_at": "2023-06-20 17:36:08",
            "updated_at": "2023-06-20 17:36:08",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Восковые мелки Классика( круглые) 6 цв",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "12С 860-08",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38ac",
                "name": "Карандаши цветные"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d38854f916c76ec39b2",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d38854f916c76ec39b1/64919d38854f916c76ec39b2/product.jpeg"
                }
            ],
            "description": "Мелки изготавливаются на основе восков с добавлением масла и высококачественных пигментов. Предназначены для рисования и письма по разным поверхностям бумаге, картону, дереву, ткани, стеклу, камням.\n\n  Яркие насыщенные цвета\n  Прочные\n  Не требуют затачивания, но при необходимости можно использовать обычную точилку\n  Экономичные\n  Штрихи свободно наносятся на поверхность\n  Не пачкают руки\n  Прекрасно рисуют в различных техниках в сочетании с акварелью, гуашью и т.д\n Безопасны для детей (безопас",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Luch"
                }
            ]
        },
        "price": {
            "id": 139670,
            "created_at": "2023-06-20 05:36:08",
            "updated_at": "2023-06-20 05:36:08",
            "is_active": true,
            "item": {
                "id": 80550,
                "global_item_id": "64919d38854f916c76ec39b1",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279231,
                "created_at": "2023-06-20 05:36:08",
                "updated_at": "2023-06-20 05:36:08",
                "amount": 5497,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279230,
                "created_at": "2023-06-20 05:36:08",
                "updated_at": "2023-06-20 05:36:08",
                "amount": 5497,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d38854f916c76ec39b1",
            "name": "Восковые мелки Классика( круглые) 6 цв",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d38854f916c76ec39b3",
            "created_at": "2023-06-20 17:36:08",
            "updated_at": "2023-06-20 17:43:21",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Пластилин мягкий Кроха 12 цветов",
            "measurement": 0,
            "package_measurements": [],
            "code": null,
            "sku": "23С 1484-08",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": true,
            "category": {
                "id": "649176cf854f916c76ec3915",
                "name": "Пластилин"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d38854f916c76ec39b4",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d38854f916c76ec39b3/64919d38854f916c76ec39b4/product.jpeg"
                }
            ],
            "description": "Пластилин создан в соответствии с предложениями и рекомендациями педагогов, работающих с детьми младшего дошколного возраста. Пластилин настолько мягкий, что не требует предварительного разминания, поэтому, в первую очередь,  является идеальным пластилином для малышей, которые только начинают лепить.\n\n Перед началом лепки не требует предварительного разминания, при этом сохраняет все свойства классического пластилина\n Идеально держит форму\nПластилин  не липнет к рукам и не пачкается\n Имеет чист",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Luch"
                }
            ]
        },
        "price": {
            "id": 139672,
            "created_at": "2023-06-20 05:36:08",
            "updated_at": "2023-06-20 05:36:08",
            "is_active": true,
            "item": {
                "id": 80551,
                "global_item_id": "64919d38854f916c76ec39b3",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279235,
                "created_at": "2023-06-20 05:36:08",
                "updated_at": "2023-06-20 05:36:08",
                "amount": 17950,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279234,
                "created_at": "2023-06-20 05:36:08",
                "updated_at": "2023-06-20 05:36:08",
                "amount": 17950,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d38854f916c76ec39b3",
            "name": "Пластилин мягкий Кроха 12 цветов",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d39854f916c76ec39b5",
            "created_at": "2023-06-20 17:36:09",
            "updated_at": "2023-06-20 17:43:24",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Пластилин мягкий Кроха 6 цветов",
            "measurement": 0,
            "package_measurements": [],
            "code": null,
            "sku": "12С 863-08",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": true,
            "category": {
                "id": "649176cf854f916c76ec3915",
                "name": "Пластилин"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d39854f916c76ec39b6",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d39854f916c76ec39b5/64919d39854f916c76ec39b6/product.jpeg"
                }
            ],
            "description": "Пластилин создан в соответствии с предложениями и рекомендациями педагогов, работающих с детьми младшего дошкольного возраста. Пластилин настолько мягкий, что не требует предварительного разминания, поэтому, в первую очередь,  является идеальным пластилином для малышей, которые только начинают лепить.\n\n Перед началом лепки не требует предварительного разминания, при этом сохраняет все свойства классического пластилина\n Идеально держит форму\nПластилин  не липнет к рукам и не пачкается\n Имеет чист",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Luch"
                }
            ]
        },
        "price": {
            "id": 139674,
            "created_at": "2023-06-20 05:36:09",
            "updated_at": "2023-06-20 05:36:09",
            "is_active": true,
            "item": {
                "id": 80552,
                "global_item_id": "64919d39854f916c76ec39b5",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279239,
                "created_at": "2023-06-20 05:36:09",
                "updated_at": "2023-06-20 05:36:09",
                "amount": 11750,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279238,
                "created_at": "2023-06-20 05:36:09",
                "updated_at": "2023-06-20 05:36:09",
                "amount": 11750,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d39854f916c76ec39b5",
            "name": "Пластилин мягкий Кроха 6 цветов",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d3b854f916c76ec39b7",
            "created_at": "2023-06-20 17:36:11",
            "updated_at": "2023-06-20 17:36:11",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Оснастка для печати Trodat 4638 Printy (black)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "4638Printy (black)",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176cf854f916c76ec390c",
                "name": "Оснастки для печати"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d3b854f916c76ec39b8",
                    "name": "product.png",
                    "path": "62d00bf2e86565691e261807/64919d3b854f916c76ec39b7/64919d3b854f916c76ec39b8/product.png"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Trodat"
                }
            ]
        },
        "price": {
            "id": 139676,
            "created_at": "2023-06-20 05:36:11",
            "updated_at": "2023-06-20 05:36:11",
            "is_active": true,
            "item": {
                "id": 80553,
                "global_item_id": "64919d3b854f916c76ec39b7",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279243,
                "created_at": "2023-06-20 05:36:11",
                "updated_at": "2023-06-20 05:36:11",
                "amount": 73500,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279242,
                "created_at": "2023-06-20 05:36:11",
                "updated_at": "2023-06-20 05:36:11",
                "amount": 73500,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d3b854f916c76ec39b7",
            "name": "Оснастка для печати Trodat 4638 Printy (black)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d50854f916c76ec39b9",
            "created_at": "2023-06-20 17:36:32",
            "updated_at": "2023-06-20 17:36:32",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Оснастка для печати Trodat 9430 MobPriy (Anthraci)",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "9430 MbPrinty/Ant",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176cf854f916c76ec390c",
                "name": "Оснастки для печати"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d50854f916c76ec39ba",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d50854f916c76ec39b9/64919d50854f916c76ec39ba/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Trodat"
                }
            ]
        },
        "price": {
            "id": 139678,
            "created_at": "2023-06-20 05:36:32",
            "updated_at": "2023-06-20 05:36:32",
            "is_active": true,
            "item": {
                "id": 80554,
                "global_item_id": "64919d50854f916c76ec39b9",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279247,
                "created_at": "2023-06-20 05:36:32",
                "updated_at": "2023-06-20 05:36:32",
                "amount": 38721,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279246,
                "created_at": "2023-06-20 05:36:32",
                "updated_at": "2023-06-20 05:36:32",
                "amount": 38721,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d50854f916c76ec39b9",
            "name": "Оснастка для печати Trodat 9430 MobPriy (Anthraci)",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d51854f916c76ec39bb",
            "created_at": "2023-06-20 17:36:33",
            "updated_at": "2023-06-20 17:36:33",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Карандаш косметический детский декоративный в наборе 6шт Amos",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "FD5B3B",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176cf854f916c76ec3914",
                "name": "Наборы для творчества"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d51854f916c76ec39bc",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d51854f916c76ec39bb/64919d51854f916c76ec39bc/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Amos"
                }
            ]
        },
        "price": {
            "id": 139680,
            "created_at": "2023-06-20 05:36:33",
            "updated_at": "2023-06-20 05:36:33",
            "is_active": true,
            "item": {
                "id": 80555,
                "global_item_id": "64919d51854f916c76ec39bb",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279251,
                "created_at": "2023-06-20 05:36:33",
                "updated_at": "2023-06-20 05:36:33",
                "amount": 13953,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279250,
                "created_at": "2023-06-20 05:36:33",
                "updated_at": "2023-06-20 05:36:33",
                "amount": 13953,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d51854f916c76ec39bb",
            "name": "Карандаш косметический детский декоративный в наборе 6шт Amos",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d51854f916c76ec39bd",
            "created_at": "2023-06-20 17:36:33",
            "updated_at": "2023-06-20 17:36:33",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Клей с блестками Amos 22 мл 24",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "GCL22D24",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176d0854f916c76ec391a",
                "name": "Клей для творчества"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d51854f916c76ec39be",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d51854f916c76ec39bd/64919d51854f916c76ec39be/product.jpeg"
                }
            ],
            "description": "С оформительским клеем Glitter Glue можно создать множество интересных вещей. Он отлично подходит для украшения поделок, новогодних шаров, блокнотов, стеклянных бутылок.\nИм можно декорировать открытки, игрушки, елочные шары, фоторамки, подарки, рисовать на бумаге, картоне, дереве, стекле, ткани и любых других поверхностях.",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Amos"
                }
            ]
        },
        "price": {
            "id": 139682,
            "created_at": "2023-06-20 05:36:34",
            "updated_at": "2023-06-20 05:36:34",
            "is_active": true,
            "item": {
                "id": 80556,
                "global_item_id": "64919d51854f916c76ec39bd",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279255,
                "created_at": "2023-06-20 05:36:34",
                "updated_at": "2023-06-20 05:36:34",
                "amount": 134389,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279254,
                "created_at": "2023-06-20 05:36:34",
                "updated_at": "2023-06-20 05:36:34",
                "amount": 134389,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d51854f916c76ec39bd",
            "name": "Клей с блестками Amos 22 мл 24",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d54854f916c76ec39bf",
            "created_at": "2023-06-20 17:36:36",
            "updated_at": "2023-06-20 17:36:36",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Набор витражных красок с витражами Amos",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "SD10P6-C",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176cf854f916c76ec3914",
                "name": "Наборы для творчества"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d54854f916c76ec39c0",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d54854f916c76ec39bf/64919d54854f916c76ec39c0/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Amos"
                }
            ]
        },
        "price": {
            "id": 139684,
            "created_at": "2023-06-20 05:36:36",
            "updated_at": "2023-06-20 05:36:36",
            "is_active": true,
            "item": {
                "id": 80557,
                "global_item_id": "64919d54854f916c76ec39bf",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279259,
                "created_at": "2023-06-20 05:36:36",
                "updated_at": "2023-06-20 05:36:36",
                "amount": 31534,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279258,
                "created_at": "2023-06-20 05:36:36",
                "updated_at": "2023-06-20 05:36:36",
                "amount": 31534,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d54854f916c76ec39bf",
            "name": "Набор витражных красок с витражами Amos",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d57854f916c76ec39c1",
            "created_at": "2023-06-20 17:36:39",
            "updated_at": "2023-06-20 17:36:39",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Формы д раск в комп с набор красок Amos mobile",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "GD10P10MK",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176cf854f916c76ec3914",
                "name": "Наборы для творчества"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d57854f916c76ec39c2",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d57854f916c76ec39c1/64919d57854f916c76ec39c2/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Amos"
                }
            ]
        },
        "price": {
            "id": 139686,
            "created_at": "2023-06-20 05:36:39",
            "updated_at": "2023-06-20 05:36:39",
            "is_active": true,
            "item": {
                "id": 80558,
                "global_item_id": "64919d57854f916c76ec39c1",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279263,
                "created_at": "2023-06-20 05:36:39",
                "updated_at": "2023-06-20 05:36:39",
                "amount": 56800,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279262,
                "created_at": "2023-06-20 05:36:39",
                "updated_at": "2023-06-20 05:36:39",
                "amount": 56800,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d57854f916c76ec39c1",
            "name": "Формы д раск в комп с набор красок Amos mobile",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
    {
        "item": {
            "id": "64919d6f854f916c76ec39c3",
            "created_at": "2023-06-20 17:37:03",
            "updated_at": "2023-06-20 17:37:03",
            "deleted_at": null,
            "is_deleted": false,
            "name": "Маркер текстовой Schneider Job GB",
            "measurement": 0,
            "package_measurements": [],
            "code": "",
            "sku": "302481",
            "barcodes": [],
            "is_state_controlled": false,
            "is_favorite": false,
            "category": {
                "id": "649176c8854f916c76ec38b2",
                "name": "Маркеры текстовые"
            },
            "tax": null,
            "images": [
                {
                    "id": "64919d6f854f916c76ec39c4",
                    "name": "product.jpeg",
                    "path": "62d00bf2e86565691e261807/64919d6f854f916c76ec39c3/64919d6f854f916c76ec39c4/product.jpeg"
                }
            ],
            "description": "",
            "organization_id": "62d00bf2e86565691e261807",
            "tasnif_info": null,
            "description_attributes": [
                {
                    "key": "brand",
                    "value": "Schneider"
                }
            ]
        },
        "price": {
            "id": 139688,
            "created_at": "2023-06-20 05:37:03",
            "updated_at": "2023-06-20 05:37:03",
            "is_active": true,
            "item": {
                "id": 80559,
                "global_item_id": "64919d6f854f916c76ec39c3",
                "global_organization_id": "62d00bf2e86565691e261807"
            },
            "common_price": {
                "id": 279267,
                "created_at": "2023-06-20 05:37:03",
                "updated_at": "2023-06-20 05:37:03",
                "amount": 8500,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "bulk_price": {
                "id": 279266,
                "created_at": "2023-06-20 05:37:03",
                "updated_at": "2023-06-20 05:37:03",
                "amount": 8500,
                "currency": {
                    "id": 47,
                    "global_currency_id": 41,
                    "name": "UZS",
                    "global_organization_id": "62d00bf2e86565691e261807",
                    "is_national": true
                }
            },
            "contractor": null,
            "global_account_id": null
        },
        "warehouse_states": {
            "id": "64919d6f854f916c76ec39c3",
            "name": "Маркер текстовой Schneider Job GB",
            "organization_id": "62d00bf2e86565691e261807",
            "warehouse_items": []
        }
    },
]
