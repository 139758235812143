<template>
    <div class="navbar-container d-flex content align-items-center justify-content-between">
        <b-modal id="ShoppingCartModal" hide-header hide-footer size="xl" body-class="p-0">
            <OrderShoppingCart @close="$bvModal.hide('ShoppingCartModal')"/>
        </b-modal>
        <ul class="nav navbar-nav">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>
        <b-navbar-nav class="nav align-items-center ml-auto">
            <feather-icon
                :badge="countOrderItems"
                class="text-body cursor-pointer"
                icon="ShoppingCartIcon"
                @click="$bvModal.show('ShoppingCartModal')"
                size="21"
            />
            <user-dropdown class="ml-2"/>
        </b-navbar-nav>
    </div>
</template>

<script>
import {BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import translate from "../../translation/translate";
import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown";
import CartDropdown from "@core/layouts/components/app-navbar/components/CartDropdown";
import LanguageSelector from "@/components/LanguageSelector";
import { mapGetters } from 'vuex'
import OrderShoppingCart from '@/components/OrderShoppingCart'

export default {
    components: {
        OrderShoppingCart,
        LanguageSelector,
        CartDropdown,
        UserDropdown,
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,

        // Navbar Components
        DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {
            },
        },
    },
    data() {
        return {
            translate,
            searchString: ''
        }
    },
    computed: {
        ...mapGetters({
            countOrderItems: 'countOrderItems'
        }),
        contractorLinks() {
            return [
                {
                    icon: 'ShoppingBagIcon',
                    name: 'user-orders',
                    title: this.translate.translate("key", "moizakazi"),
                },
            ]
        },
    },
}
</script>
