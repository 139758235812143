<template>
    <div>
        <b-modal id="EditOrderDetailsComponent" hide-header hide-footer body-class="p-0">
            <EditOrderDetailsComponent @close="$bvModal.hide('EditOrderDetailsComponent')"
                                       @onSave="onSaveOrderDetails"/>
        </b-modal>

        <!-- Cart Header -->
        <div class="d-flex align-items-center border-bottom p-1">
            <h4 class="notification-title mb-0 mr-auto">
                {{ translate.translate('key', 'korzinka') }}
            </h4>
            <b-badge
                pill
                variant="light-primary"
            >
                <span style="font-size: 15px">{{ countOrderItems }} шт</span>
            </b-badge>
            <b-btn-close class="ml-1" @click="$emit('close')"/>
        </div>

        <!-- Cart Items -->
        <vue-perfect-scrollbar
            v-if="countOrderItems"
            :settings="perfectScrollbarSettings"
            class="scrollable-container media-list scroll-area shopping-cart-content"
        >
            <b-media class="cursor-pointer dotted-gradient" v-for="(item, index) in order.items" :key="index">
                <div class="w-100">
                    <div class="d-flex justify-content-between mb-1">
                        <div class="mr-1">
                            <h6>{{ index + 1 }}) {{ item.name }}</h6>
                            <div class="text-info small" v-if="getItem(item.id)">
                                <em>Артикул: {{ getItem(item.id).item.sku }}</em>
                            </div>
                        </div>
                        <div>
                            <feather-icon
                                icon="TrashIcon"
                                class="cart-item-remove cursor-pointer text-muted"
                                @click.stop="removeItemFromCart(item.id)"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="cart-item-qty">
                            <b-input-group size="sm" class="p-0" style="width: 200px">
                                <b-input-group-prepend>
                                    <b-button variant="primary" class="py-0" size="sm"
                                              @click="decrementQuantity(item)"
                                    >
                                        <feather-icon icon="MinusIcon"/>
                                    </b-button>
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="item.quantity"
                                    type="number"
                                    min="0"
                                    class="text-center"
                                    @input="changeQuantity(item)"
                                    number
                                />
                                <b-input-group-append>
                                    <b-button variant="primary" class="py-0" size="sm" @click="incrementQuantity(item)">
                                        <feather-icon icon="PlusIcon"/>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <h5 class="cart-item-price text-warning mt-2">
                            Цена: {{ item.price_amount | decimal }}
                        </h5>
                    </div>
                </div>
            </b-media>
        </vue-perfect-scrollbar>

        <!-- Cart Footer -->
        <div class="d-flex justify-content-between p-1 border-top" v-if="countOrderItems">
            <h4 class="font-weight-bolder mb-0">
                {{ translate.translate('key', 'obshayaSumma') }}:
            </h4>
            <h4 class="text-warning font-weight-bolder mb-0">
                {{ totalAmount | decimal }}
            </h4>
        </div>
        <b-button
            v-if="countOrderItems"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="loading"
            @click="$bvModal.show('EditOrderDetailsComponent')"
        >
            <b-spinner v-if="loading" small></b-spinner>
            {{ translate.translate('key', 'oformit') }}
        </b-button>

        <p v-if="!countOrderItems" class="m-0 p-1 text-center">
            Ваша корзина пуста 🙃
        </p>
    </div>
</template>

<script>
import {BBadge, BButton, BFormSpinbutton, BImg, BLink, BMedia, BNavItemDropdown} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import translate from '@/translation/translate'
import {mapActions, mapGetters} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {_} from 'vue-underscore'
import EditOrderDetailsComponent from "@/components/EditOrderDetailsComponent";

export default {
    name: 'ShoppingCart',
    components: {
        EditOrderDetailsComponent,
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BImg,
        BFormSpinbutton,
        VuePerfectScrollbar,
        BButton,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            translate,
            items: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            order: 'getOrder',
            orderId: 'getOrderId',
            totalAmount: 'totalOrder',
            products: 'getProducts',
            countOrderItems: 'countOrderItems'
        }),
    },
    methods: {
        ...mapActions(['updateOrderItem', 'deleteOrderItem', 'checkoutOrder', 'updateOrder']),
        onSaveOrderDetails() {
            this.$bvModal.hide('EditOrderDetailsComponent')
            this.onCheckoutOrder();
        },
        onCheckoutOrder() {
            this.loading = true
            this.updateOrder({
                order_id: this.order.id,
                payload: {
                    contractor_id: this.order.contractor.id,
                    order: {
                        number: this.order.order.number,
                        date: new Date(this.order.order.date).getTime() > new Date().getTime() ?
                            this.$options.filters.api_date_format(new Date(this.order.order.date)) :
                            this.$options.filters.api_date_format(new Date())
                    },
                    contract: null,
                    expires_on: this.order.expires_on ? this.$options.filters.api_datetime_format(this.order.expires_on) : null,
                }
            }).then(res => {
                this.checkoutOrder(this.order.id)
                    .then(res => {
                        this.successMessage()
                        this.$store.commit('setOrder', null)

                    })
                    .catch(err => {
                        this.errorMessage()
                    })
                    .finally(err => {
                        this.loading = false;
                        this.$emit('close')
                    })

            }).catch(err => {
                this.errorMessage()
            })
        },
        successMessage() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Успешно',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
        },
        errorMessage() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Возникла ошибка',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        },
        removeItemFromCart(order_item_id) {
            this.loading = true
            this.deleteOrderItem({
                order_id: this.order.id,
                item_id: order_item_id
            })
                .then(res => {
                })
                .catch(err => this.errorMessage()).finally(() => {
                this.loading = false
            })
        },
        incrementQuantity(order_item) {
            let orderItem = this.order ? this.order.items.find(oi => oi.id === order_item.id) : null
            if (orderItem && order_item.quantity > 0) {
                this.updateOrderItem({
                    item_id: orderItem.id,
                    order_id: this.order.id,
                    quantity: orderItem.quantity + 1,
                })
                    .then(res => {
                    })
                    .catch(err => {
                        this.errorMessage()
                    })
            }
        },
        decrementQuantity(order_item) {
            let orderItem = this.order ? this.order.items.find(oi => oi.id === order_item.id) : null

            if (orderItem && orderItem.quantity === 1) {
                this.deleteOrderItem({
                    order_id: this.order.id,
                    item_id: orderItem.id
                })
                    .then(res => {
                    })
                    .catch(err => this.errorMessage())
            } else if (orderItem && orderItem.quantity > 1) {
                this.updateOrderItem({
                    item_id: orderItem.id,
                    order_id: this.order.id,
                    quantity: orderItem.quantity - 1,
                })
                    .then(res => {
                    })
                    .catch(err => {
                        this.errorMessage()
                    })
            }
        },
        changeQuantity: _.debounce(function (item) {
            this.onChangeQuantity(item)
        }, 1000),
        onChangeQuantity(order_item) {
            if (order_item.quantity > 0) {
                let foundOrderItem = this.order ? this.order.items.find(oi => oi.id === order_item.id) : null
                if (foundOrderItem) {
                    this.updateOrderItem({
                        item_id: foundOrderItem.id,
                        order_id: this.order.id,
                        quantity: foundOrderItem.quantity,
                    })
                        .then(res => {
                        })
                        .catch(err => {
                            this.errorMessage()
                        })
                }
            }
        },
        getItem(item_id) {
            if (this.products.length === 0) return null
            return this.products.find(product => {
                return product.item.id === item_id
            })
        }
    },
}
</script>

<style lang="scss">
.shopping-cart-content {
    height: calc(var(--vh, 0.6vh) * 100);
}

.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps-scrollbar-y-rail {
    opacity: 9.6;
}
</style>
