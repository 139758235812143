<template>
    <b-nav-item-dropdown
        class="dropdown-cart"
        menu-class="dropdown-menu-media"
        style="left: auto !important;"
        right
    >
        <template #button-content>
            <feather-icon
                :badge="countOrderItems"
                class="text-body"
                icon="ShoppingCartIcon"
                size="21"
            />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                    {{ translate.translate("key", "korzinka") }}
                </h4>
                <b-badge
                    pill
                    variant="light-primary"
                >
                    <span style="font-size: 15px">{{ countOrderItems }} шт</span>
                </b-badge>
            </div>
        </li>

        <!-- Cart Items -->
        <vue-perfect-scrollbar
            v-if="countOrderItems"
            :settings="perfectScrollbarSettings"
            class="scrollable-container media-list scroll-area"
            tagname="li"
        >
            <b-media class="cursor-pointer" v-for="(item, index) in order.items" :key="index">
                <div class="w-100">
                    <div class="d-flex align-items-center justify-content-between mb-1">
                        <div class="mr-1">
                            <h6>{{ index + 1 }}) {{ item.name }}</h6>
                            <div class="text-info small" v-if="getItem(item.id)">
                                <em>Артикул: {{ getItem(item.id).item.sku }}</em>
                            </div>
                        </div>
                        <feather-icon
                            icon="XIcon"
                            class="cart-item-remove cursor-pointer"
                            @click.stop="removeItemFromCart(item.id)"
                        />
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="cart-item-qty">
                            <b-input-group size="sm" class="p-0" style="width: 200px">
                                <b-input-group-prepend>
                                    <b-button variant="primary" class="py-0" size="sm"
                                              @click="decrementQuantity(item)">
                                        <feather-icon icon="MinusIcon"/>
                                    </b-button>
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="item.quantity"
                                    type="number"
                                    min="0"
                                    class="text-center"
                                    @input="changeQuantity(item)"
                                    number
                                />
                                <b-input-group-append>
                                    <b-button variant="primary" class="py-0" size="sm" @click="incrementQuantity(item)">
                                        <feather-icon icon="PlusIcon"/>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <h5 class="cart-item-price text-warning">
                            {{ item.price_amount | decimal }}
                        </h5>
                    </div>
                </div>
            </b-media>
        </vue-perfect-scrollbar>

        <li v-if="countOrderItems" class="dropdown-menu-footer">
            <div class="d-flex justify-content-between mb-2">
                <h4 class="font-weight-bolder mb-0">
                    {{ translate.translate("key", "obshayaSumma") }}:
                </h4>
                <h4 class="text-warning font-weight-bolder mb-0">
                    {{ totalAmount | decimal }}
                </h4>
            </div>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="onCheckoutOrder"
            >
                {{ translate.translate("key", "oformit") }}
            </b-button>
        </li>

        <p v-if="!countOrderItems" class="m-0 p-1 text-center">
            Ваша корзина пуста 🙃
        </p>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import translate from "@/translation/translate";
import {_} from "vue-underscore";

export default {
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BImg,
        BFormSpinbutton,
        VuePerfectScrollbar,
        BButton,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            translate,
            items: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
        }
    },
    computed: {
        ...mapGetters({
            order: 'getOrder',
            orderId: 'getOrderId',
            totalAmount: 'totalOrder',
            products: 'getProducts',
            countOrderItems: 'countOrderItems'
        }),
    },
    methods: {
        ...mapActions(['updateOrderItem', 'deleteOrderItem', 'checkoutOrder']),
        onCheckoutOrder() {
            this.checkoutOrder(this.order.id).then(res => {
                this.successMessage();
                this.$store.commit('setOrder', null);

            }).catch(err => {
                this.errorMessage();
            });
        },
        successMessage() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Успешно',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
        },
        errorMessage() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Возникла ошибка',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        },
        removeItemFromCart(order_item_id) {
            this.deleteOrderItem({order_id: this.order.id, item_id: order_item_id}).then(res => {
            }).catch(err => this.errorMessage());
        },
        incrementQuantity(order_item) {
            let orderItem = this.order ? this.order.items.find(oi => oi.id === order_item.id) : null
            if (orderItem && order_item.quantity > 0) {
                this.updateOrderItem({
                    item_id: orderItem.id,
                    order_id: this.order.id,
                    quantity: orderItem.quantity + 1,
                }).then(res => {
                }).catch(err => {
                    this.errorMessage()
                })
            }
        },
        decrementQuantity(order_item) {
            let orderItem = this.order ? this.order.items.find(oi => oi.id === order_item.id) : null

            if (orderItem && orderItem.quantity === 1) {
                this.deleteOrderItem({order_id: this.order.id, item_id: orderItem.id}).then(res => {
                }).catch(err => this.errorMessage());
            } else if (orderItem && orderItem.quantity > 1) {
                this.updateOrderItem({
                    item_id: orderItem.id,
                    order_id: this.order.id,
                    quantity: orderItem.quantity - 1,
                }).then(res => {
                }).catch(err => {
                    this.errorMessage()
                });
            }
        },
        changeQuantity: _.debounce(function (item) {
            this.onChangeQuantity(item);
        }, 1000),
        onChangeQuantity(order_item) {
            if (order_item.quantity > 0) {
                let foundOrderItem = this.order ? this.order.items.find(oi => oi.id === order_item.id) : null;
                if (foundOrderItem) {
                    this.updateOrderItem({
                        item_id: foundOrderItem.id,
                        order_id: this.order.id,
                        quantity: foundOrderItem.quantity,
                    }).then(res => {
                    }).catch(err => {
                        this.errorMessage()
                    });
                }
            }
        },
        getItem(item_id) {
            if (this.products.length === 0) return null
            return this.products.find(product => {
                return product.item.id === item_id
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
    .media {
        .media-aside {
            align-items: center;
        }
    }
}
</style>
