<template>
    <div
        class="main-menu menu-fixed menu-accordion menu-shadow d-flex align-items-end flex-column"
        :class="[
          { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
          'menu-light']"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >

        <!-- main menu header-->
        <div class="navbar-header expanded">
            <slot
                name="header"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :toggleCollapsed="toggleCollapsed"
                :collapseTogglerIcon="collapseTogglerIcon"
            >
                <ul class="nav navbar-nav flex-row">

                    <!-- Logo & Text -->
                    <li class="nav-item mr-auto">
                        <b-link
                            class="navbar-brand mt-1"
                            to="/"
                        >
                            <!--                          <span class="brand-logo">
                                                        <b-img
                                                            :src="appLogoImage"
                                                            alt="logo"
                                                        />
                                                      </span>-->
                            <h2 class="brand-text" style="padding-left: 4px !important;" v-if="currentBot">
                                {{ currentBot.name | kit_cut(13)}}
                            </h2>
                        </b-link>
                    </li>

                    <!-- Toggler Button -->
                    <li class="nav-item nav-toggle">
                        <b-link class="nav-link modern-nav-toggle mt-1">
                            <feather-icon
                                icon="XIcon"
                                size="20"
                                class="d-block d-xl-none"
                                @click="toggleVerticalMenuActive"
                            />
                        </b-link>
                    </li>
                </ul>
            </slot>
        </div>
        <!-- / main menu header-->

        <div
            :class="{'d-block': shallShadowBottom}"
            class="shadow-bottom"
        />

        <!-- main menu content-->
        <vue-perfect-scrollbar
            style="width: 260px;"
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
        >
            <vertical-nav-menu-items
                :items="pageLinks"
                class="navigation navigation-main"
            />
        </vue-perfect-scrollbar>
        <!-- /main menu content-->

        <div class="mb-auto" style="padding: 9px">
            <small v-if="lang === 'ru'"><em>© Телеграм бот разработан</em>
                <a href="https://hippo.uz" target="_blank" class="font-weight-bold text-primary"
                   style="margin-left: 5px"><b>Hippo.uz</b></a></small>

            <small v-else><em>© Ushbu telegram bot</em>
                <a href="https://hippo.uz" target="_blank" class="font-weight-bold text-primary"
                   style="margin-left: 5px"><b>Hippo.uz</b></a> <em>tomonidan yaratilgan.</em></small>
        </div>
    </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {BLink, BImg} from 'bootstrap-vue'
import {provide, computed, ref} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {$themeConfig} from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import {mapGetters} from "vuex";
import translate from '@/translation/translate'

export default {
    name: 'VerticalNavMenu',
    components: {
        VuePerfectScrollbar,
        VerticalNavMenuItems,
        BLink,
        BImg,
    },
    props: {
        isVerticalMenuActive: {
            type: Boolean,
            required: true,
        },
        toggleVerticalMenuActive: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            translate
        }
    },
    setup(props) {
        const {
            isMouseHovered,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            updateMouseHovered,
        } = useVerticalNavMenu(props)

        const {skin} = useAppConfig()

        // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
        const shallShadowBottom = ref(false)

        provide('isMouseHovered', isMouseHovered)

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

        // App Name
        const {appName, appLogoImage} = $themeConfig.app

        return {
            navMenuItems,
            perfectScrollbarSettings,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            isMouseHovered,
            updateMouseHovered,
            collapseTogglerIconFeather,

            // Shadow Bottom
            shallShadowBottom,

            // Skin
            skin,

            // App Name
            appName,
            appLogoImage,
        }
    },
    computed: {
        ...mapGetters({treeCategories: "getCategoriesTree", currentBot: "getCurrentBot"}),
        sidebarLinks() {
            const catalogs = [{
                header: this.translate.translate("key", "katalogtovarov")
            }].concat(this.remapCategories(this.treeCategories))

            return [...this.pageLinks, ...catalogs]
        },
        lang() {
            return translate.getUserLocaleName()
        },
        pageLinks() {
            return [
                {
                    header: this.translate.translate("key", "menyu"),
                },
                {
                    icon: 'ShoppingCartIcon',
                    route: 'new-order',
                    title: 'Новый заказ',
                },
                {
                    icon: 'HomeIcon',
                    route: 'branches',
                    title: 'Торговые точки',
                    disabled: true
                },
                {
                    icon: 'ShoppingBagIcon',
                    route: 'user-orders',
                    title: this.translate.translate("key", "moizakazi"),
                },
                {
                    icon: 'UsersIcon',
                    route: 'all-contractors',
                    title: 'Клиенты',
                },
                {
                    icon: 'DollarSignIcon',
                    route: 'kpi',
                    title: 'KPI',
                    disabled: true
                },
            ]
        },
    },
    methods: {
        remapCategories(treeCategories) {
            return treeCategories.map(category => {
                let children = null;
                if (category.childs.length > 0) {
                    children = this.remapCategories(category.childs)
                }
                let result = {
                    title: category.name,
                    route: {name: 'category', query: {category_id: category.id}}
                }
                if (children) {
                    result.children = children
                }
                return result
            })
        },
    },
}
</script>
<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
