<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
    >
        <template #button-content>
            <b-avatar
                size="40"
                variant="light-primary"
                badge
                class="badge-minimal"
                badge-variant="success"
            >
                <feather-icon
                    icon="UserIcon"
                    size="22"
                />
            </b-avatar>
        </template>

        <b-dropdown-divider/>
        <b-dropdown-item
            :to="{name: 'settings'}"
            link-class="d-flex align-items-center"
        >
            <feather-icon
                size="16"
                icon="SettingsIcon"
                class="mr-50"
            />
            <span>{{ translate.translate("key", "nastroyki") }}</span>
        </b-dropdown-item>
        <!--        <b-dropdown-item
                    link-class="d-flex align-items-center"
                >
                    <feather-icon
                        size="16"
                        icon="CheckSquareIcon"
                        class="mr-50"
                    />
                    <span>Task</span>
                </b-dropdown-item>
                <b-dropdown-item
                    link-class="d-flex align-items-center"
                >
                    <feather-icon
                        size="16"
                        icon="MessageSquareIcon"
                        class="mr-50"
                    />
                    <span>Chat</span>
                </b-dropdown-item>

                <b-dropdown-divider/>

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                >
                    <feather-icon
                        size="16"
                        icon="SettingsIcon"
                        class="mr-50"
                    />
                    <span>Settings</span>
                </b-dropdown-item>
                <b-dropdown-item
                    link-class="d-flex align-items-center"
                >
                    <feather-icon
                        size="16"
                        icon="CreditCardIcon"
                        class="mr-50"
                    />
                    <span>Pricing</span>
                </b-dropdown-item>
                <b-dropdown-item
                    link-class="d-flex align-items-center"
                >
                    <feather-icon
                        size="16"
                        icon="HelpCircleIcon"
                        class="mr-50"
                    />
                    <span>FAQ</span>
                </b-dropdown-item>-->
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import translate from "@/translation/translate";
import {mapGetters} from "vuex";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
    },
    data() {
        return {
            translate,
        }
    },
    computed: {
        ...mapGetters({currentBot: 'getCurrentBot'})
    }
}
</script>
