<template>
    <li
        v-if="canViewVerticalNavMenuGroup(item)"
        class="nav-item has-sub"
        :class="{
          'open': isOpen,
          'disabled': item.disabled,
          'sidebar-group-active': isActive,
        }"
    >
        <b-link
            class="d-flex align-items-center"
            @click="() => updateGroupOpen(!isOpen)"
        >
            <span class="text-truncate" style="font-size: 12px">{{ t(item.title) }}</span>
        </b-link>
        <b-collapse
            v-model="isOpen"
            class="menu-content"
            tag="ul"
        >
            <component
                :is="resolveNavItemComponent(child)"
                v-for="child in item.children"
                :key="child.header || child.title"
                ref="groupChild"
                :item="child"
            />
        </b-collapse>
    </li>
</template>

<script>
import {BLink, BBadge, BCollapse} from 'bootstrap-vue'
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from '@core/layouts/utils'
import {useUtils as useI18nUtils} from '@core/libs/i18n'
import {useUtils as useAclUtils} from '@core/libs/acl'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
    name: 'VerticalNavMenuGroup',
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        BLink,
        BBadge,
        BCollapse,
    },
    mixins: [mixinVerticalNavMenuGroup],
    computed: {
        images(){
            return [
                {
                    img: require('@/assets/images/kanstik/ofisniy_tovary.png'),
                    name: 'ОФИСНЫЕ ТОВАРЫ',
                },
                {
                    img: require('@/assets/images/kanstik/техника.png'),
                    name: 'ОФИСНАЯ ТЕХНИКА',
                },
                {
                    img: require('@/assets/images/kanstik/папкиисистемы_архивации.png'),
                    name: 'ПАПКИ И СИСТЕМЫ АРХИВАЦИИ',
                },
                {
                    img: require('@/assets/images/kanstik/shkolniy.png'),
                    name: 'ШКОЛЬНЫЕ ТОВАРЫ',
                },
                {
                    img: require('@/assets/images/kanstik/бытовые_товары.png'),
                    name: 'БЫТОВЫЕ ТОВАРЫ',
                },
                {
                    img: require('@/assets/images/kanstik/бумажная_продукция.png'),
                    name: 'БУМАЖНАЯ ПРОДУКЦИЯ',
                },
                {
                    img: require('@/assets/images/kanstik/бумажная_продукция.png'),
                    name: 'ШТЕМПЕЛЬНАЯ ПРОДУКЦИЯ',
                },
                {
                    img: require('@/assets/images/kanstik/длятворчества.png'),
                    name: 'ТОВАРЫ ДЛЯ ТВОРЧЕСТВА',
                },
                {
                    img: require('@/assets/images/kanstik/письменные.png'),
                    name: 'ПИСЬМЕННЫЕ ПРИНАДЛЕЖНОСТИ',
                },
                {
                    img: require('@/assets/images/kanstik/бумажная_продукция.png'),
                    name: 'ОФИСНАЯ БУМАГА',
                },
            ]
        }
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const {
            isOpen,
            isActive,
            updateGroupOpen,
            updateIsActive,
        } = useVerticalNavMenuGroup(props.item)

        const {t} = useI18nUtils()
        const {canViewVerticalNavMenuGroup} = useAclUtils()

        return {
            resolveNavItemComponent,
            isOpen,
            isActive,
            updateGroupOpen,
            updateIsActive,

            // ACL
            canViewVerticalNavMenuGroup,

            // i18n
            t,
        }
    },
    watch: {
        /*'isOpen'(val) {
            if ((val || (this.$route.query.category_id === this.item.route.query.category_id)) && window.innerWidth > 1200) {
                if (this.item && this.item.route.query.category_id) {
                    let routeQuery = {};
                    routeQuery.category_id = this.item.route.query.category_id
                    if (this.$route.name === 'marketplace-table') {
                        this.$router.replace({
                            name: 'marketplace-table',
                            query: routeQuery
                        }).catch(err => {
                        })
                    } else {
                        this.$router.replace({
                            name: 'category',
                            query: routeQuery
                        }).catch(err => {
                        })
                    }
                }
            }
        }*/
    },
    methods: {
        getIcon(category){
            let icon =  this.images.find(image=> image.name === category.title)
            if (icon)
                return icon.img
            return ''
        }
    }
}
</script>

<style>

</style>
