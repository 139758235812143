<template>
    <div v-if="order">
        <div class="d-flex align-items-center border-bottom p-1">
            <h4 class="notification-title mb-0 mr-auto">
                {{ translate.translate('key', 'orderDetailsButtonText') }}
            </h4>
            <b-btn-close class="ml-1" @click="$emit('close')"/>
        </div>

        <div class="p-1">
            <div class="datepicker-filter mb-2">
                <label class="mb-0_5rem">{{translate.translate('key', 'dataZakaza')}}</label>
                <datepicker
                    ref="startDate"
                    v-model="order.order.date"
                    :placeholder="translate.translate('key', 'dataZakaza')"
                    :language="lang"
                    :monday-first="true"
                    :disabled-dates="disabledDatesPredictor"
                />
            </div>
            <div class="datepicker-filter mb-2">
                <label class="mb-0_5rem">{{translate.translate('key', 'dataistecheniyaSroka')}}</label>
                <datepicker
                    ref="startDate"
                    v-model="order.expires_on"
                    placeholder='Дата истечения срока'
                    :language="lang"
                    :monday-first="true"
                    :clear-button="true"
                    :disabled-dates="disabledDatesPredictor"
                />
            </div>
        </div>

        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="$emit('onSave')">
            <feather-icon icon="SaveIcon"/>
            Сохранить
        </b-button>
    </div>
</template>

<script>
import translate from "@/translation/translate";
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";
import {mapGetters} from "vuex";
import Ripple from "vue-ripple-directive";

export default {
    name: "EditOrderDetailsComponent",
    components: {Datepicker},
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters({
            order: 'getOrder',
        }),
        disabledDatesPredictor: {
            get() {
                return {
                    to: this.disableDates
                }
            }
        },
        disableDates() {
            let date = new Date();
            let res = date.setDate(date.getDate() - 1);
            return new Date(res)
        },
    },
    data() {
        return {
            lang: ru,
            translate
        }
    }
}
</script>

<style scoped>

</style>
